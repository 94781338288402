.Menu{
    display: flex;
}

.MenuContainer{
    display: flex;
    padding-left: 4%;
    padding-top: 5%;
    padding-bottom: 5%;
    /* padding: 4%; */
    background-color: #F4F4F4;
    margin-bottom: 6%;
    margin-left: 6%;
    margin-right: 4%;
    border: none;
    border-radius: 15px;
}

.MenuContainerNonActive{
    display: flex;
    padding-left: 4%;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-bottom: 6%;
    margin-left: 6%;
    margin-right: 4%;
    height: fit-content;
}

.MenuApp{
    min-width: 22%;
    min-height: 100vh;
}

.MenuActive{
    font-size: 2.5vh;
    padding-left: 10%;
    border: none;
    border-radius: 12px;
    width: 100%;
    color: #2A85FF;
    display: flex;
    align-items: center;
}

.MenuNonActive{
    font-size: 2.5vh;
    padding-left: 10%;
    border: none;
    border-radius: 12px;
    width: 100%;
    color: #6F767E;
    display: flex;
    align-items: center;
}

.MenuLogout{
    font-size: 2.5vh;
    padding-left: 10%;
    color: #E47E7E;
    font-weight: 600;
}

.profilSearchBoxContainer{
    width: 100%;
    background: #FFFFFF;
    padding-top: 2%;
    padding-bottom: 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.MainMenu{
    width: 78%;
    background: var(--Neutral-02, #F4F4F4);
}

.profilContainer{
    padding-left: 1%;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-right: 5%;
    border: 2px solid ; 
    border-radius: 10px;
    margin-right: 2%;
    background-color: transparent; 
}

.searchContainer{
    padding-left: 2%;
    width: 100%;
}

.search-box-container{
    background: var(--Neutral-02, #F4F4F4);
    width: 60%;
    border: none;
    outline: none;
    border-radius: 10px;
    padding: 0.7%;
    margin-left: 2%;
    display: flex;
    align-items: center;
}

.search-box{
    border: none;
    border-radius: 10px;
    background: var(--Neutral-02, #F4F4F4);
    width: 60%;
    outline: none;
    padding: 1%;
    font-size: 2.5vh;
}

.MenuName{
    font-size: 3.5vh;
    font-weight: 600;
    padding-top: 1.5%;
    padding-left: 2%;
    margin-bottom: 1%;
    width: max-content;
}

.MenuSpace{
    height: 12.5vh;
}

.UnderConstructionContainer{
    width: 100%;
    height: 86%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.UnderConstructionImage{
    width: 35%;
}

.UnderConstructionTitle{
    font-size: 5vh;
    font-weight: 800;
    margin-bottom: 2%;
}

.UnderConstructionCaption{
    font-size: 2.5vh;
    font-weight: 300;
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
}

.new-form-title{
    font-size: 2.5vh;
    font-weight: 700;
}

.AddItemTemplate{
    color: #2A85FF;
    font-weight: 700;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    padding-top: 1.7%;
    padding-bottom: 1.7%;
    /* background-color: #E47E7E; */
}

.ItemNameTemplateForm{
    width: 100%;
    display: flex;
    align-items: center;
}

.DeleteTemplateForm{
    display: flex;
    justify-content: end;
    color: #E47E7E;
    font-weight: 700;
    padding-top: 1%;
    padding-bottom: 1%;
    width: 100%;
    align-items: center;
    height: 100%;
}

.VATandTotal{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin: 20px auto;
    display: flex;
    align-items: center;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.ErrorBox-Title{
    font-size: 3vh;
    font-weight: 700;
    margin-bottom: 1%;
}

.ErrorBox-Caption{
    font-size: 2vh;
    font-weight: 400;
}

@media screen and (max-width: 1100px) {
    .MenuApp{
        display: none;
    }

    .UnderConstructionContainer{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    

    .UnderConstructionImage{
        width: 55%;
    }

    .UnderConstructionCaption{
        font-size: 1.5vh;
        font-weight: 300;
        text-align: center;
        padding-left: 10%;
        padding-right: 10%;
    }

    .profilSearchBoxContainer{
        width: 100%;
        background: #FFFFFF;
        padding-top: 2%;
        padding-bottom: 2%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .MenuName{
        font-size: 2.5vh;
        font-weight: 600;
        padding-top: 1.5%;
        padding-left: 2%;
        margin-bottom: 1%;
        width: max-content;
    }

    .MainMenu{
        width: 100%;
        min-height: 100vh;
        background: var(--Neutral-02, #F4F4F4);
    }

    .UnderConstructionTitle{
        font-size: 3vh;
        font-weight: 800;
        margin-bottom: 2%;
    }
}

@media screen and (max-width: 800px) {
    .MenuApp{
        display: none;
    }

    .search-box-container{
        background: var(--Neutral-02, #F4F4F4);
        width: 100%;
        border: none;
        outline: none;
        border-radius: 10px;
        padding: 0.7%;
        margin-left: 2%;
        margin-right: 2%;
        display: flex;
        align-items: center;
    }

    .profilContainer{
        display: none;
    }

    .MainMenu{
        width: 100%;
        min-height: 100vh;
        background: var(--Neutral-02, #F4F4F4);
    }

}
.App {
  display: flex;
  align-items: center;
  justify-self: center;
  min-height: 100vh; 
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.AppLeftSection {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AppRightSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-logo{
  width: 70vh;
}

.LoginTitle{
  font-size: 6vh;
  font-weight: 500;
  padding-bottom: 1%;
  color: #1A1D1F;
}

.LoginDesc{
  font-size: 3vh;
  font-weight: 300;
  padding-bottom: 4%;
  color: #818385;
}

.LoginForm {
  padding-bottom: 2%;
  width: 100%; 
  max-width: 400px; 
  margin: 0 auto;
}

.textbox-container{
  width: 100%;
  border-radius: 10%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-items: center;
}

.LabelTextBox{
  padding-bottom: 6%;
  font-size: 2.5vh;
  font-weight: 600;
  color: #818385;
}

.icon {
  margin-right: 5px;
  padding: 1%;
}

.textbox {
  border: none;
  outline: none;
  width: 100%;
  font-size: medium;
  padding: 2%;
}

.passwordTextBox{
  border: none;
  outline: none;
  width: 100%;
  font-size: medium;
  padding: 2%;
  
}

.ForgotPassword{
  padding-bottom: 2%;
  color: rgba(110, 46, 253, 0.90);
  font-size: 2vh;
  font-weight: 600;
  display: flex;
  justify-content: start;
}

.SubmitButton{
  background: rgba(110, 46, 253, 0.50);
  color: #FFFFFF;
  padding: 2.5%;
  box-shadow: none;
  outline: none;
  border: none;
  border-radius: 10px;
  margin-bottom: 5%;
  width: 20%;
  font-size: 2vh;
  font-weight: 700;
}

.GradientText{
  display: flex;
  padding-left: 8%;
}

.GradientTextOne{
  font-size: 2.2vh;
  color: #818385;
  font-weight: 600;
  margin-right: 4px;
}

.GradientTextTwo{
  font-size: 2.2vh;
  color: rgba(110, 46, 253, 0.90);
  font-weight: 600;
  margin-right: 4px;
}

.scrollable-horizontal {
  overflow-x: auto;
  white-space: nowrap;
}

.scrollable-table-container {
  max-height: 300px; /* Adjust the max height as needed */
  overflow-y: auto;
}

.scrollable-vertical {
  white-space: nowrap; /* Optional: Prevent text wrapping */
}

.grid-container{
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding-top: 1%;
  padding-bottom: 2%;
  height: max-content;
  gap: 30px;
}

.grid-container-10pax{
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding-top: 1%;
  padding-bottom: 2%;
  height: max-content;
  gap: 10px;
}

.grid-container-scrollable{
  overflow-x: hidden;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1%;
  padding-bottom: 2%;
  height: max-content;
  gap: 30px;
  width: max-content;
}

.grid-container-form-submit{
  display: flex;
  justify-content: right;
}

.grid-container-with-padding-left{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1%;
  padding-bottom: 2%;
  padding-left: 2%;
  padding-right: 2%;
  gap: 30px;
}

.grid-container-smaller{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1%;
  padding-bottom: 1%;
}

.card-for-grid{
  background-color: #FFFFFF;
  border: none;
  border-radius: 10px;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-left: 2%;
  margin-right: 2%;
  padding: 2%;
}

.card-for-grid-finance{
  background-color: #FFFFFF;
  border: none;
  border-radius: 10px;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-left: 2%;
  margin-right: 2%;
  padding: 2%;
  width: 100vh;
  height: 100%;
  text-align: center;
}

.card-for-grid-100{
  background-color: #FFFFFF;
  border: none;
  border-radius: 10px;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-left: 2%;
  margin-right: 2%;
  padding: 2%;
  width: 100%;
}

.seeAllCard{
  background-color: #FFFFFF;
  border: none;
  border-radius: 10px;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-left: 2%;
  margin-right: 2%;
  padding-left: 2%;
  padding-right: 2%;
}

.card-for-grid-settings{
  background-color: #FFFFFF;
  border: none;
  border-radius: 10px;
  padding-top: 10%;
  padding-bottom: 10%;
  padding-left: 5%;
  padding-right: 5%;
  margin-left: 10%;
  margin-right: 10%;
  text-align: center;
}

.card-for-grid-divide-two{
  background-color: #FFFFFF;
  border: none;
  border-radius: 10px;
  margin: 2%;
  padding: 2%;
  width: 100%;
}

.card-for-grid-divide-two-no{
  background-color: #FFFFFF;
  border: none;
  border-radius: 10px;
  margin: 2%;
  width: 100%;
}

.four-grid-card-one{
  border-radius: 12px;
  background: rgba(181, 228, 202, 0.25);
  padding-left: 2%;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-right: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-right: 3%;
}

.four-grid-card-two{
  border-radius: 12px;
  background: rgba(255, 0, 0, 0.25);
  padding-left: 2%;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-right: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-right: 3%;
}

.four-grid-card-three{
  border-radius: 12px;
  background: rgba(255, 184, 0, 0.25);
  padding-left: 2%;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-right: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-right: 3%;
}

.four-grid-card-four{
  border-radius: 12px;
  background: rgba(118, 128, 177, 0.25);
  padding-left: 2%;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-right: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.card-headline{
  color: var(--Neutral-07, #1A1D1F);
  font-size: 3.5vh;
  font-weight: 600;
}

.card-headline-padding{
  color: var(--Neutral-07, #1A1D1F);
  font-size: 3.5vh;
  font-weight: 600;
  padding-left: 4%;
  padding-top: 4%;
  padding-bottom: 1%;
}

.four-grid-card-title{
  font-size: 2vh;
  font-weight: 600;
  padding-bottom: 3%;
}

.four-grid-card-desc{
  font-size: 3vh;
  font-weight: 700;
}

.space-between{
  height: 4vh;
}

.space-between-1vh{
  height: 1vh;
}


.space-between-width{
  width: 4vh;
}

.space-between-width-10{
  width: 10vh;
}

.space-between-list{
  height: 0.5vh;
}

.see-all-text{
  color: #2A85FF;
  font-size: 2.2vh;
  font-weight: 500;
}

.detail-card{
  background: #2A85FF;
  color: #FFFFFF;
  padding: 4%;
  border: none;
  border-radius: 15px;
  width: 15%;
  text-align: center;
}

.detail-card-smaller{
  background: #2A85FF;
  color: #FFFFFF;
  padding: 1.3%;
  border: none;
  border-radius: 15px;
  width: 10%;
  text-align: center;
}

.seeAllDetailCard{
  background: #2A85FF;
  color: #FFFFFF;
  padding: 1.7%;
  border: none;
  border-radius: 15px;
  width: 18%;
  text-align: center;
}

.column-container{
  display: flex;
  flex-direction: column;
}

.list-title{
  font-size: 2.5vh;
  font-weight: 600;
  padding-bottom: 3%;
}

.list-desc{
  font-size: 2vh;
  font-weight: 300;
}

.settings-title{
  font-size: 3vh;
  font-weight: 600;
  padding-top: 10%;
  padding-bottom: 8%;
}

.settings-desc{
  font-size: 1.6vh;
  font-weight: 400;
  padding-bottom: 2%;
  padding-left: 3%;
  padding-right: 3%;
}

.settings-desc-2{
  font-size: 1.6vh;
  font-weight: 400;
  padding-bottom: 2%;
  /* padding-left: 1%; */
  padding-right: 3%;
}

.table-layout{
  width: 100%;
  margin-left: 2%;
  margin-right: 2%;
}

.table-title{
  font-size: 2.5vh;
  margin-bottom: 10%;
}

.table-content{
  font-size: 2vh;
  text-align: center;
}

.dialog-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-container {
  background-color: white; /* White card */
  padding: 20px;
  border-radius: 15px;
  width: max-content;
  padding: 3%;
  display: flex;
  justify-content: center;
}

.dialog-content {
  justify-content: center;
  text-align: center;
  width: max-content;
}

.dialog-content p {
  margin-bottom: 10px;
}

.verification-code{
  color: #2A85FF;
  font-size: 7vh;
  font-weight: 700;
}

.verification-next{
  font-size: 1.9vh;
  margin-top: 2%;
  margin-bottom: 5%;
  font-weight: 500;
}

.verification-button{
  color: #2A85FF;
  font-weight: 700;
  font-size: 2vh;
}

.close-text-dialog{
  font-weight: 700;
  font-size: 2vh;
}

.ErrorBox-Container{
  padding: 4%;
  margin-bottom: 2%;
  width: 70%;
  text-align: left;
  display: flex;
  flex-direction: column;
  background: rgba(255, 0, 0, 0.25);
  border: none;
  border-radius: 15px;
}

.create-new-button{
  background: #2A85FF;
  color: #FFFFFF;
  margin-right: 2%;
  padding: 1.5%;
  font-weight: 600;
  border: none;
  border-radius: 15px;
}

.new-form-title{
  font-size: 3vh;
}

.new-form-dropdown{
  border-radius: 10px;
  margin-top: 2%;
  max-width: 100vh;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 2%;
}

.TwoGridForm{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2.5%;
  width: 100%;
  text-align: left;
}

.TwoGridFormSmaller{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5%;
  width: 100%;
  text-align: left;
}

.TwoGridFormContainer{
  width: 100%;
  height: 100%;
}

.StatusEnd{
  text-align: center;
  background-color: #818385;
  padding-top: 2%;
  padding-bottom: 2%;
  border-radius: 15px;
}

.TwoGridFormLabel{
  margin-bottom: 12%;
  font-size: 2.2vh;
  font-weight: 600;
  color: #818385;
}

.formLabel{
  font-size: 2.2vh;
  font-weight: 700;
  color: #818385;
  margin-bottom: 1%;
  width: 100%;
}

.only-100-width{
  width: 250px;
  display: flex;
  flex-direction: column;
  align-content: start;
}

.only-100-width-row{
  width: 250px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: end;
}

.only-100{
  width: 100%;
  text-align: center;
  padding-top: 2%;
  padding-bottom: 2%;
}

.only-100-finance{
  width: 100%;
  text-align: center;
  padding-top: 1%;
  padding-bottom: 1%;
}
 
.only-100-end{
  display: flex;
  justify-content: end;
  width: 100%;
  text-align: center;
  padding-top: 1%;
  padding-bottom: 1%;
  gap: 15px;
}

.only-100-start{
  width: 100%;
  text-align: left;
  padding-top: 2%;
  padding-bottom: 2%;
}

.only-100-start-nopadding{
  width: max-content;
  text-align: left;
  padding-top: 2%;
  padding-bottom: 2%;
}

.only-100-end-nopadding{
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: max-content;
  text-align: right;
  padding-top: 2%;
  padding-bottom: 2%;
}

.only-max-width{
  width: max-content;
  display: flex;
  flex-direction: column;
  align-content: start;
}

.autofill-form{
  font-size: 3vh;
  color: #2A85FF;
  font-weight: 700;
}

.only-100-width-card-approve{
  width: 100%;
  text-align: center;
  padding-top: 2%;
  padding-bottom: 2%;
  border-radius: 15px;
  color: green;
  border: 2px solid green; 
  font-size: 2.5vh;
  font-weight: 700;
}

.only-100-width-card-reject{
  width: 100%;
  text-align: center;
  padding-top: 2%;
  padding-bottom: 2%;
  border-radius: 15px;
  color: red;
  border: 2px solid red; 
  font-size: 2.5vh;
  font-weight: 700;
}

.title-for-card{
  font-size: 3vh;
  font-weight: 700;
}

.title-for-card-2{
  font-size: 2.5vh;
  font-weight: 600;
  padding-bottom: 1%;
}

.company-data-purchase{
  font-size: 2.3vh;
  font-weight: 700;
}

.calculator-container{
  display: flex;
  flex-direction: column;
  margin-right: 2%;
  text-align: end;
}

.seeAllTitle{
  font-size: 2.5vh;
  font-weight: 700;
  margin-bottom: 3.5%;
}

.seeAllDetail{
  font-size: 1.8vh;
  font-weight: 400;
}

.FilterDate{
  margin-left: 2%;
  display: flex;
  align-items: center;
  gap: 30px;
}

.FilterExport {
  margin-right: 2.5%;
  color: green;          
  font-size: 2.5vh;
  font-weight: 700;
  border: 2px solid green; 
  background-color: transparent; 
  padding: 1.3%;
  border-radius: 15px;
}


.only-100-width-card-submit{
  width: 100%;
  text-align: center;
  padding-top: 2%;
  padding-bottom: 2%;
  border-radius: 15px;
  color: #2A85FF;
  border: 2px solid #2A85FF; 
  font-size: 2.5vh;
  font-weight: 700;
}

.search-icon {
  display: none; /* Initially hide the search icon */
}

.target-company-one{
  display: flex;
  text-align: center;
  justify-content: center;
  color: #1A1D1F;
  font-weight: 700;
  font-size: 2vh;
  padding-bottom: 2%;
}

.target-company-two{
  display: flex;
  text-align: center;
  color: #787878;
}

.target-company-three{
  display: flex;
  text-align: center;
  color: #2A85FF;
}

.target-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  row-gap: 20px;
  column-gap: 70px;
  padding-top: 1%;
  padding-left: 1.2%;
  padding-right: 4.5%;
  height: max-content;
}

.new-row-start {
  clear: both; /* This assumes you are using floats; if using Flexbox or Grid, this would change */
}

.chart{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.redFont{
  color: red;
  font-weight: 700;
  font-size: 2vh;
}

.blueFont{
  color: #2A85FF;
  font-weight: 700;
  font-size: 2vh;
}

.table-100-w{
  width: 100%;
}

.pick-product{
  margin-top: 2%;
  font-size: 1.8vh;
  color: #787878;
}

.custom-file-upload {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 6px;
  cursor: pointer;
  background: linear-gradient(270deg, #3fa1a9, #79f1a4);
  margin-bottom: 25px;
}

.img-wrap{
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
}

.img-upload:before{
  content: "\f093";
  font-size: 90px;
  position: absolute;
  padding-top: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #63d3a6;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  opacity: 0;
  transition: .5s ease;
  background-color: #fff;
}

.img-upload:hover:before{
 opacity: 1;
}

img {
  width: auto;
  height: 100%;
}

label{
  font-weight: 700;
  color: #676767;
}

.date-picker{
  width: max-content;
  padding: 4%;
}

.pagination{
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 3%;
  margin-bottom: 10%;
  list-style-type: none;
}

.pagination button {
  margin: 0 5px;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  background-color: #FFFFFF;
}

.pagination button.active {
  background-color: #007bff;
  border: none;
  color: white;
}

.pagination button[disabled] {
  cursor: not-allowed;
  border: none;
  opacity: 0.5;
}
.suggestions-container {
  position: relative;
}

.suggestions-list {
  list-style: none;
  padding: 0px;
  margin: 0px;
  border: 1px solid #ccc;
  position: absolute;
  z-index: 1000;
  background-color: #fff;
  width: 37vh;
  max-height: 200px;
  overflow-y: auto;
  
}

.suggestions-list li {
  padding: 10px;
  padding-right: 0px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}

@media screen and (max-width: 1200px) {
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh; 
    min-width: 50vh;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .ForgotPassword{
    padding-bottom: 2%;
    color: rgba(110, 46, 253, 0.90);
    font-size: 1.5vh;
    font-weight: 600;
  }

  .GradientTextOne{
    font-size: 1.5vh;
    color: #818385;
    font-weight: 600;
    margin-right: 4px;
  }
  
  .GradientTextTwo{
    font-size: 1.5vh;
    color: rgba(110, 46, 253, 0.90);
    font-weight: 600;
    margin-right: 4px;
  }

  .search-icon {
      display: inline-block;
      width: max-content;
  }

  .company-data-purchase{
    font-size: 1.3vh;
    font-weight: 700;
  }

  .LabelTextBox{
    /* margin-bottom: 1%; */
    font-size: 1.7vh;
    font-weight: 600;
    color: #818385;
  }

  .card-for-grid{
    background-color: #FFFFFF;
    border: none;
    border-radius: 10px;
    margin-top: 3%;
    /* margin-bottom: 1%; */
    margin-left: 2%;
    margin-right: 2%;
    padding: 2%;
  }

  .card-for-grid-settings{
    background-color: #FFFFFF;
    border: none;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    padding: 10%;
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
  }

  .settings-title{
    font-size: 2vh;
    font-weight: 600;
    padding-top: 10%;
    padding-bottom: 8%;
  }

  .settings-desc{
    font-size: 1vh;
    font-weight: 400;
    padding-bottom: 2%;
    padding-left: 3%;
    padding-right: 3%;
  }

  .list-desc{
    font-size: 1vh;
    font-weight: 300;
  }

  .list-title{
    font-size: 1.5vh;
    font-weight: 600;
    padding-bottom: 3%;
  }

  .card-headline{
    color: var(--Neutral-07, #1A1D1F);
    font-size: 2vh;
    font-weight: 600;
  }

  .formLabel{
    font-size: 1.5vh;
    font-weight: 700;
    color: #818385;
    margin-bottom: 1%;
    width: 100%;
  }

  .see-all-text{
    color: #2A85FF;
    font-size: 1.2vh;
    font-weight: 500;
  }

  .AppLeftSection {
    display: none;
  }

  .AppRightSection {
    min-width: 100vh;
    min-height: 100vh;
    overflow-x: scroll;
  }

  .autofill-form{
    font-size: 1.9vh;
    color: #2A85FF;
    font-weight: 700;
  }

  .LoginTitle{
    font-size: 4vh;
    font-weight: 500;
    text-align: center;
  }
  
  .LoginDesc{
    font-size: 2vh;
    font-weight: 300;
    text-align: center;
    padding-bottom: 5%;
  }

  .LoginForm{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    padding-bottom: 2%;
    /* background-color: aqua; */
    min-width: 100vh;
  }

  .LoginMobile{
    min-width: 30%;
  }

  .GradientText{
    display: flex;
    padding-left: 3%;
  }

  .grid-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1%;
    padding-bottom: 1%;
    height: max-content;
  }

  .four-grid-card-title{
    font-size: 1.5vh;
    font-weight: 600;
    padding-bottom: 3%;
  }

  .four-grid-card-desc{
    font-size: 2vh;
    font-weight: 700;
  }

  .title-for-card{
    font-size: 2vh;
    font-weight: 700;
  }
}

@media screen and (max-width: 800px) {
  .only-100-width{
    margin-bottom: 3%;
  }
  .four-grid-container{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 3%;
      padding-right: 5%;
      padding-top: 2%;
      padding-bottom: 2%;
    }

    .card-time-search{
      display: none;
    }

    .grid-container{
      display: flex;
      flex-direction: column;
      padding-left: 5%;
      padding-right: 3%;
    }

    .four-grid-card-one{
      border-radius: 12px;
      background: rgba(181, 228, 202, 0.25);
      padding-left: 2%;
      padding-top: 3%;
      padding-bottom: 3%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      margin-right: 3%;
      margin-bottom: 5%;
    }

    .four-grid-card-two{
      border-radius: 12px;
      background: rgba(255, 0, 0, 0.25);
      padding-left: 2%;
      padding-top: 3%;
      padding-bottom: 3%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      margin-right: 3%;
      margin-bottom: 5%;
    }

    .four-grid-card-three{
      border-radius: 12px;
      background: rgba(255, 184, 0, 0.25);
      padding-left: 2%;
      padding-top: 3%;
      padding-bottom: 3%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      margin-right: 3%;
      margin-bottom: 5%;
    }
    
    .four-grid-card-four{
      border-radius: 12px;
      background: rgba(118, 128, 177, 0.25);
      padding-left: 2%;
      padding-top: 3%;
      padding-bottom: 3%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      margin-bottom: 3%;
    }

    .four-grid-card-desc{
      font-size: 4vh;
      font-weight: 700;
    }

    .grid-container-smaller{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 1%;
      padding-bottom: 1%;
      padding-left: 2%;
      padding-right: 2%;
    }
}